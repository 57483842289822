import { useState, useEffect } from 'preact/hooks'
import firebase from 'firebase/app'

import 'firebase/database'
import 'typeface-montserrat'

import './styles/index.css'
import firebaseConfig from './configs/firebaseConfig'
import StatusIndicator from './components/StatusIndicator'
import ProgressBarEx from './components/ProgressBarEx'

const MAX_DISTANCE = 3000

const formatter = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 1,
  maximumFractionDigits: 1,
})

const App = () => {
  const [distance, setDistance] = useState(null)
  const [temperature, setTemperature] = useState(null)
  const [status, setStatus] = useState(-1)
  const waterLevel = (1 - distance / MAX_DISTANCE) * 100

  useEffect(() => {
    if (status === -1) return
    const timer = setTimeout(() => setStatus(prv => prv + 1), 10000)

    return () => clearTimeout(timer)
  }, [status])

  useEffect(() => {
    if (typeof window === 'undefined') return

    const app = firebase.initializeApp(firebaseConfig)
    const db = app.database()

    db.ref('sensor001/distance').on(
      'value',
      s => (s.val() >= 0 && setDistance(s.val())) || setStatus(0)
    )
    db.ref('sensor001/temperature').on(
      'value',
      s => (s.val() > -1000 && setTemperature(s.val())) || setStatus(0)
    )

    return () => app.delete()
  }, [])

  return (
    <div className='container'>
      <div className='box'>
        <ProgressBarEx val={waterLevel} />
        <h1>💧 {distance >= 0 ? formatter.format(waterLevel) : '...'}%</h1>
        <h1>🌡️ {temperature || '...'}°C</h1>
      </div>
      <span className='footer-text'>{'Copyright ® 2019 by Toan'}</span>
      <StatusIndicator val={status} />
    </div>
  )
}
export default App
