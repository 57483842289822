import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { transparentize } from 'polished'

const startColor = transparentize(.2, '#7FDBFF')
const endColor = transparentize(.4, '#0074D9')

const Wrapper = styled.div`
  padding-top: 50px;
  min-width: 100px;
  font-size: 50px;
  perspective: 12em;
  perspective-origin: 50% 50%;

  transition: transform 0.5s ease-in-out;
  &:hover {  
    transform: scale3d(1.05, 1.05, 1.05);
    transform-style: preserve-3d;
  }
`
const Bar = styled.div`
  font-size: 1em;
  position: relative;
  display: inline-block;
  width: calc(100% - 1em);
  height: 1em;
  /* transition: all 0.5s ease-in-out; */
  transform-style: preserve-3d;
  transform: rotateX(60deg);
`
const barFaceMix = css`
  position: absolute;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  backface-visibility: visible;
  transition: transform 0.5s ease-out;

  /* Position */
  transform-origin: 50% 100%;
  /* Red */
  background-color: rgba(255, 255, 255, 0.3);
`
const BarLeft = styled.div`
  ${barFaceMix}
  width: 1em;
  transform: rotateX(-90deg) rotateY(-90deg) translateX(-0.5em) translateZ(0.5em);
  transition-delay: 1.5s;
`
const BarRight = styled.div`
  ${barFaceMix}
  left: auto;
  right: -.5em;
  width: 1em;
  transform: rotateX(-90deg) rotateY(90deg) translateX(0.5em);
  transition-delay: 0.5s;
`
const BarFloor = styled.div`
  ${barFaceMix}
  box-shadow: 
    0 -0.2em 1em rgba(0, 0, 0, 0.15),
    0 0.2em 0.1em -5px rgba(0, 0, 0, 0.3),
    0 -0.75em 1.75em rgba(254, 254, 254, 0.6);
`
const BarRoof = styled.div`
  ${barFaceMix}
  transform: translateZ(1em);
  transition-delay: 2s;
`
const BarBack = styled.div`
  ${barFaceMix}
  transform: rotateX(-90deg) rotateY(0deg) translateZ(-1em);
  transition-delay: 1s;
`
const BarFront = styled.div`
  ${barFaceMix}
  transform: rotateX(-90deg);
  transition-delay: 0s;
`
const BarFaceFiller = styled.div`
  background: ${p => p.color1 || 'transparent'};
  width: 100%;
  height: 100%;
`

const BarFaceGradientFiller = styled.div`
  width: 100%;
  height: 100%;
  transition: padding .5s ease-out;

  background-image: linear-gradient(
    to right,
    ${p => p.color1} 0%,
    ${p => p.color2} 100%
  );
  background-size: 100%;
  background-clip: content-box;
  padding-right: ${p => Math.max(0, 100 - p.fillLevel)}%;
`

const ProgressBarEx = ({ val = 0 }) => (
  <Wrapper>
    <Bar>
      <BarLeft children={<BarFaceFiller color1={startColor} />} />
      <BarRight children={<BarFaceFiller color1={val >= 100 && endColor} />} />
      <BarFloor children={<BarFaceGradientFiller fillLevel={val} color1={startColor} color2={endColor} />} />
      <BarRoof children={<BarFaceGradientFiller fillLevel={val} color1={startColor} color2={endColor} />} />
      <BarBack children={<BarFaceGradientFiller fillLevel={val} color1={startColor} color2={endColor} />} />
      <BarFront children={<BarFaceGradientFiller fillLevel={val} color1={startColor} color2={endColor} />} />
    </Bar>
  </Wrapper>
)

export default ProgressBarEx