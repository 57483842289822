const StatusIndicator = ({ val = 0 }) => {
  let className = 'led '
  switch (val) {
    case 0: className += 'led-green'; break
    case 1: className += 'led-yellow'; break
    case 2: className += 'led-orange'; break
    case 3: className += 'led-red'; break
    default: className += 'led-black'; break
  }

  return (
    <div className='led-box'>
      <div className={className} />
    </div>
  )
}

export default StatusIndicator